import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import { GOOGLE_RECAPTCHA_SITE_KEY } from './constants/config';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import ServiceWorkerWrapper from './ServiceWorkerWrapper';

const IdleApp = React.lazy(() => import(/* webpackChunkName: "IdleApp" */ './IdleApp'));

const Main = () => {
  return (
    <Provider store={store}>
      <GoogleReCaptchaProvider
        useRecaptchaNet
        reCaptchaKey={GOOGLE_RECAPTCHA_SITE_KEY}
        scriptProps={{ async: true, defer: true, appendTo: 'body' }}
      >
        <Suspense fallback={<div className="loading"/>}>
          <IdleApp/>
        </Suspense>
      </GoogleReCaptchaProvider>
    </Provider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Main/>);

// serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();